import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { Wrapped } from "@components/Layouts";
import { Notes, PageTitle, ViewError, BackButton } from "@components/shared";
import {
  PatientForm,
  PatientHistory,
  PatientProcedures,
  PatientPractices,
} from "@components/Patient";
import PhoneManagement from "@components/PhoneManagement";
import { AuditManagement } from "@components/Audit";
import { getRoleInfo } from "@util/roleInfo";

export default function PatientsDetail({ isAdmin, match, history }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [patient, setPatient] = useState(populatePatient());

  const patientId = match.params.id;
  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/patients/${patientId}/`)
      .then(({ data }) => {
        setLoading(false);
        setPatient(populatePatient(data.response[0]));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!patient) return null;

  return (
    <div>
      <PageTitle
        title={
          roleInfo.user
            ? `${patient.first_name} ${patient.last_name}`
            : patient.info_string
        }
        subtitle={
          <React.Fragment>Patient ID {patient.formatted_ident}</React.Fragment>
        }
        links={[
          { text: "Detail", to: match.url },
          // { text: "Patient Status", to: `${match.url}/status` },
          { text: "Clinical Background", to: `${match.url}/history` },
          { text: "Procedures", to: `${match.url}/procedures` },
          { text: "Trusts", to: `${match.url}/practices`, admin: true },
          { text: "Audits", to: `${match.url}/audits`, admin: true },
        ]
          .filter((f) => (!isAdmin ? !f.admin : true))
          .map((l, i) => ({ ...l, key: i + 1 }))}
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped>
              <PatientForm
                birthYear={patient.birth_year}
                centralCornealThickness={patient.central_corneal_thickness}
                dataConsentedAt={patient.data_consented_at}
                ethnicity={patient.ethnicity}
                firstName={patient.first_name}
                gender={patient.gender}
                hospitalNumber={patient.hospital_number}
                lastName={patient.last_name}
                newlyDiagnosedDate={patient.newly_diagnosed_date}
                patientIdent={patient.patient_ident}
                onSuccess={fetchData}
                otherEthnicity={patient.other_ethnicity}
                patientId={patientId}
                practicePatientId={patient.practice_patient_id}
                registryExitDate={patient.registry_exit_date}
                status={patient.status}
                id={patientId}
                race={patient.race}
              />
            </Wrapped>
            <PhoneManagement patientId={patientId} />
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/procedures`}>
          <PatientProcedures
            patientId={patientId}
            onCreate={(proId) => history.push(`/procedures/${proId}`)}
            isAdmin={isAdmin}
            eyeApproval={patient.eye_approval}
          />
        </Route>
        <Route path={`${match.path}/history`}>
          <PatientHistory patientId={patientId} />
        </Route>
        {isAdmin && (
          <Route path={`${match.path}/audits`}>
            <Wrapped>
              <AuditManagement
                reqInfo={{ patient_id: patientId }}
                updateInfo={{
                  xhr: (d) => axios.put(`/patients/${patientId}/`, d),
                  req: { patient_id: patientId },
                }}
                onUpdate={fetchData}
              />
            </Wrapped>
          </Route>
        )}
        {isAdmin && (
          <Route path={`${match.path}/practices`}>
            <Wrapped>
              <PatientPractices patientId={patientId} />
            </Wrapped>
          </Route>
        )}
      </Switch>
      <Wrapped>
        <BackButton text="Back to patients" url={"/patients"} />
      </Wrapped>
      <Notes
        additionalCreateData={{ patient_id: patientId }}
        additionalFetchData={{ patient_id: patientId }}
        title="Patient Notes"
      />
    </div>
  );
}

PatientsDetail.defaultProps = {
  admin: false,
};

function populatePatient(d = {}) {
  return {
    birth_year: d.birth_year || null,
    central_corneal_thickness: d.central_corneal_thickness || "",
    data_consented_at: d.data_consented_at || null,
    ethnicity: d.ethnicity || null,
    eye_approval: d.eye_approval,
    first_name: d.first_name || "",
    gender: d.gender || null,
    hospital_number: d.hospital_number || "",
    id: d.id || null,
    info_string: d.info_string || "",
    last_name: d.last_name || "",
    newly_diagnosed_date: d.newly_diagnosed_date || null,
    patient_ident: d.patient_ident || "",
    other_ethnicity: d.other_ethnicity || "",
    patient_id: d.id || null,
    practice_patient_id: d.practice_patient_id || null,
    race: d.race || null,
    registry_exit_date: d.registry_exit_date || null,
    status: d.status || null,
    formatted_ident: d.formatted_ident || "",
  };
}
