import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Button, Text, ToggleTitle } from "@components/shared";

const StyleWrapper = styled.div`
  padding: 2em 0;

  div.audit-info {
    margin-top: 1.5em;
  }

  .add-mgmt {
    margin-top: 1.5em;

    button.active {
      background: var(--primaryColorLight) !important;
    }
  }

  &:first-of-type {
    padding-top: 0;
  }

  & + & {
    border-top: 1px solid #eee;
  }
`;

export default function JoinSection({
  activeCount,
  activeSection,
  addSection,
  error,
  loading,
  title,
  toggleOpen,
  viewOnly,
}) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(toggleOpen);
  }, [toggleOpen]);

  function onToggleClick() {
    setOpen(!isOpen);
  }

  return (
    <StyleWrapper>
      <ToggleTitle
        title={title}
        viewOnly={viewOnly}
        isOpen={isOpen}
        onClick={onToggleClick}
        activeCount={activeCount}
        loading={loading}
      />
      <React.Fragment>{activeSection}</React.Fragment>
      {isOpen && (
        <React.Fragment>
          {addSection}
          <div>
            <Button.Text onClick={onToggleClick} text="Back" />
          </div>
        </React.Fragment>
      )}
      {error && (
        <div style={{ marginTop: "1em" }}>
          <Text.Error text={error} />
        </div>
      )}
    </StyleWrapper>
  );
}

JoinSection.defaultProps = {
  error: null,
  loading: false,
  title: "",
  toggleOpen: false,
  viewOnly: false,
};
