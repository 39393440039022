import React, { useState } from "react";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";

import { statusOptions } from "./status";

export default function Table({ practiceId }) {
  const [activeFilter, setActiveFilter] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => (
        <Button.View
          url={practiceId ? `/users/${d.user_id}` : `/users/${d.id}`}
        />
      ),
    },
    {
      Header: "First Name",
      accessor: (d) => (practiceId ? d.user.first_name : d.first_name),
      id: "first_name",
      // width: 200,
    },
    {
      Header: "Last Name",
      accessor: (d) => (practiceId ? d.user.last_name : d.last_name),
      id: "last_name",
      // width: 110,
    },
    {
      Header: "Email",
      accessor: "email",
      accessor: (d) => (practiceId ? d.user.email : d.email),
      id: "email",
      // width: 200,
    },
    {
      Header: "Role",
      accessor: (d) =>
        practiceId ? d.occupational_variety_formatted : d.email,
      id: "role",
      show: practiceId ? true : false,
    },
    // {
    //   Header: "Configuration Complete",
    //   id: "config_complete",
    //   accessor: (d) => {
    //     const showWarning = d.active_practices.find((f) => f.role === "none")
    //       ? true
    //       : false;
    //     return (
    //       <div>
    //         {showWarning ? (
    //           <FaMinusCircle color="var(--negativeColor)" />
    //         ) : (
    //           <FaCheckCircle color="var(--positiveColor)" />
    //         )}
    //       </div>
    //     );
    //   },
    //   // width: 200,
    // },
  ].map((m) => ({
    ...m,
    sortable: false,
    filterable: false,
  }));

  const additionalPostData = {
    status: JSON.parse(activeFilter),
  };

  if (practiceId) {
    additionalPostData.practice_id = practiceId;
  }

  return (
    <React.Fragment>
      {!practiceId && (
        <FilterSelect
          options={dropdownOptions}
          value={activeFilter}
          onChange={(e) => setActiveFilter(e)}
          entityName="Users"
        />
      )}
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Users"
        filter={activeFilter}
        filterOptions={filterOptions}
        keyName="data"
        refresh={refresh}
        url={
          practiceId
            ? `/practices/${practiceId}/retrieve-users/`
            : "/retrieve-users/"
        }
      />
    </React.Fragment>
  );
}

Table.defaultProps = {};

const dropdownOptions = [
  { text: "All", value: JSON.stringify(statusOptions.map((s) => s.value)) },
  ...statusOptions,
];

const filterOptions = [{ name: "first_name", obj: "self" }];
