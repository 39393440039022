import { getUuid } from "@util/getUuid";

export const MODE_INCLUSIVE = "inclusive";
export const MODE_EXCLUSIVE = "exclusive";
export const MODE_NEVER = "never";

export const procedureJoinOptions = [
  {
    text: "Cataract Status",
    key_name: "cataract_statuses",
    inclusive_text: "had a certain Cataract Status",
    exclusive_text: "not had a certain Cataract Status",
    never_text: "not had a Cataract Status at all",
  },
  {
    text: "Anaesthesia",
    key_name: "anesthesias",
    inclusive_text: "had a certain anaesthesia",
    exclusive_text: "not had a certain anaesthesia",
    never_text: "not had anaesthesia at all",
  },
  {
    text: "Glaucoma Diagnosis",
    key_name: "glaucoma_diagnoses",
    inclusive_text: "had a certain Glaucoma Diagnosis",
    exclusive_text: "not had a certain Glaucoma Diagnosis",
    never_text: "not had a Glaucoma Diagnosis at all",
  },
  {
    text: "Gonioscopy",
    key_name: "gonioscopies",
    inclusive_text: "had a certain Gonioscopy",
    exclusive_text: "not had a certain Gonioscopy",
    never_text: "not had a Gonioscopy at all",
  },
  // {
  //   text: "Systemic Medication",
  //   key_name: "systemic_medications",
  //   inclusive_text: "had a certain Systemic Medication",
  //   exclusive_text: "not had a certain Systemic Medication",
  //   never_text: "not had a Systemic Medication at all",
  // },
  {
    text: "Ocular Medication",
    key_name: "ocular_medications",
    inclusive_text: "had a certain Ocular Medication",
    exclusive_text: "not had a certain Ocular Medication",
    never_text: "not had an Ocular Medication at all",
  },
  {
    text: "Previous Ocular Surgery",
    key_name: "previous_ocular_surgeries",
    inclusive_text: "had a certain Previous Ocular Surgery",
    exclusive_text: "not had a certain Previous Ocular Surgery",
    never_text: "not had a Previous Ocular Surgery at all",
  },
  {
    text: "Retinal Finding",
    key_name: "retinal_findings",
    inclusive_text: "had a certain Retinal Finding",
    exclusive_text: "not had a certain Retinal Finding",
    never_text: "not had a Retinal Finding at all",
  },
  {
    text: "SLE Finding",
    key_name: "sle_findings",
    inclusive_text: "had a certain SLE Finding",
    exclusive_text: "not had a certain SLE Finding",
    never_text: "not had a SLE Finding at all",
  },
  {
    text: "Systemic Comorbidity",
    key_name: "systemic_comorbidities",
    inclusive_text: "had a certain Systemic Comorbidity",
    exclusive_text: "not had a certain Systemic Comorbidity",
    never_text: "not had a Systemic Comorbidity at all",
  },
  {
    text: "Ocular Comorbidity",
    key_name: "ocular_comorbidities",
    inclusive_text: "had a certain Ocular Comorbidity",
    exclusive_text: "not had a certain Ocular Comorbidity",
    never_text: "not had a Ocular Comorbidity at all",
  },
  {
    text: "Complication",
    key_name: "complications",
    inclusive_text: "had a certain Complication",
    exclusive_text: "not had a certain Complication",
    never_text: "not had a Complication at all",
  },
].map((m) => ({ ...m, key: getUuid() }));

export const procedureJoinState = procedureJoinOptions.reduce((acc, cur) => {
  acc[cur.key_name] = [];
  return acc;
}, {});

export function formatProcedureJoins(data) {
  const obj = {};
  data.forEach((d) => {
    const entry = {};

    if ([MODE_EXCLUSIVE, MODE_INCLUSIVE].includes(d.mode)) {
      entry.inclusive = d.mode === MODE_INCLUSIVE ? true : false;
      entry.never = false;
    } else {
      entry.never = true;
      entry.inclusive = false;
    }

    switch (d.key_name) {
      case "cataract_statuses":
        entry.data = d.data.map((m) => ({
          id: m.id,
          trace: m.trace ? m.trace.map((mm) => mm.value) : [],
        }));
        break;
      case "gonioscopies":
        entry.data = d.data.map((m) => ({
          id: m.id,
          angular_approach: m.angular_approach
            ? m.angular_approach.map((mm) => mm.value)
            : [],
          iris_insertion: m.iris_insertion
            ? m.iris_insertion.map((mm) => mm.value)
            : [],
          meshwork: m.meshwork ? m.meshwork.map((mm) => mm.value) : [],
          peripheral_iris: m.peripheral_iris
            ? m.peripheral_iris.map((mm) => mm.value)
            : [],
        }));
        break;
      case "ocular_medications":
        entry.data = d.data.map((m) => ({
          id: m.id,
          value: m.value ? m.value.map((mm) => mm.value) : [],
        }));
        break;
      case "previous_ocular_surgeries":
        entry.data = d.data.map((m) => ({
          id: m.id,
          value: m.value ? m.value.map((mm) => mm.value) : [],
        }));
        break;
      case "retinal_findings":
        entry.data = d.data.map((m) => ({
          id: m.id,
          nerve_variety: m.nerve_variety
            ? m.nerve_variety.map((mm) => mm.value)
            : [],
        }));
        break;
      default:
        entry.data = d.data.map((m) => ({
          id: m.id,
        }));
        break;
    }
    obj[d.key_name] = entry;
  });

  return obj;
}

export function formatQueryRequest(data) {
  return {
    ...data,
  };
}
