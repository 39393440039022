import React from "react";

export default function Dashboard() {
  return (
    <div>
      <p>
        UKMPTLT:{" "}
        <a href="mailto:imperial.ukmptltstudy@nhs.net">
          imperial.ukmptltstudy@nhs.net
        </a>
      </p>
    </div>
  );
}
