import React, { useState } from "react";
import { FilterSelect, PaginatedTable, Button } from "@components/shared";

import { statusOptions, varietyOptions } from "./status";

export default function Table() {
  const [activeFilter, setActiveFilter] = useState(1);
  const [activeVariety, setActiveVariety] = useState(1);

  const additionalPostData = {
    status: JSON.parse(activeFilter),
    variety: activeVariety,
  };

  return (
    <React.Fragment>
      <FilterSelect
        options={dropdownOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Admins"
      />
      <FilterSelect
        options={varietyOptions}
        value={activeVariety}
        onChange={(e) => setActiveVariety(e)}
        entityName="Roles"
      />
      <PaginatedTable
        columns={[
          {
            Header: "",
            width: 50,
            id: "view",
            accessor: (d) => <Button.View url={`/admins/${d.id}`} />,
          },
          { Header: "First Name", accessor: "first_name" },
          { Header: "Last Name", accessor: "last_name" },
          { Header: "Email", accessor: "email" },
          { Header: "Role", accessor: "variety_formatted" },
        ].map((m) => ({ ...m, sortable: false }))}
        additionalPostData={additionalPostData}
        entityName="Admins"
        keyName="data"
        url="/retrieve-admins/"
      />
    </React.Fragment>
  );
}

const dropdownOptions = [
  { text: "All", value: JSON.stringify(statusOptions.map((s) => s.value)) },
  ...statusOptions,
];
