import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import toast from "react-hot-toast";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";
import { userVariety } from "./status";

export default function UserPracticeManagement({
  userId,
  userPractices,
  onUpdate,
}) {
  const [practices, setPractices] = useState([]);
  const [loading, setLoading] = useState(false);

  const userPracticeIds = userPractices.map((m) => m.practice_id);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchPractices();
  }, []);

  function fetchPractices() {
    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
      status: 1,
    };

    axios
      .post(`/retrieve-practices/`, req)
      .then(({ data }) => {
        setPractices(data.response[0].data);
      })
      .catch((err) => {
        setLoading(false);
        setPractices([]);
      });
  }

  const addedPractices = practices.filter((f) =>
    userPracticeIds.includes(f.id)
  );
  const availablePractices = practices.filter(
    (f) => !userPracticeIds.includes(f.id)
  );

  return (
    <div>
      <h2>Added Trusts</h2>
      {userPractices.length > 0 ? (
        <React.Fragment>
          <BasicGrid columns={1}>
            {userPractices.map((p) => (
              <PracticeItem
                key={p.practice_id}
                name={p.practice_name}
                occupationalVariety={p.occupational_variety}
                id={p.practice_id}
                userId={userId}
                joinId={p.id}
                fetchData={onUpdate}
              />
            ))}
          </BasicGrid>
        </React.Fragment>
      ) : (
        <EmptyList text="No trusts added" />
      )}
      {!roleInfo.admin_research_fellow && (
        <React.Fragment>
          <h2>Available Trusts</h2>
          {availablePractices.length > 0 ? (
            <React.Fragment>
              <BasicGrid columns={2}>
                {availablePractices.map((p) => (
                  <PracticeItem
                    key={p.id}
                    name={p.name}
                    id={p.id}
                    userId={userId}
                    fetchData={onUpdate}
                  />
                ))}
              </BasicGrid>
            </React.Fragment>
          ) : (
            <EmptyList text="No trusts available" />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

UserPracticeManagement.defaultProps = {
  userPractices: [],
};

const PracticeItem = ({
  id,
  joinId,
  userId,
  name,
  active,
  fetchData,
  occupationalVariety,
}) => {
  const [loading, setLoading] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [occupationalVarietyValue, setOccupationalVariety] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    if (occupationalVariety) {
      setOccupationalVariety(occupationalVariety);
    }
  }, [occupationalVariety]);

  function onClick() {
    setLoading(true);

    if (joinId) {
      return removePractice();
    }

    const req = {
      practice_id: id,
      user_id: userId,
      occupational_variety: occupationalVarietyValue,
    };

    axios
      .post(`/users/${userId}/practices/`, req)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function removePractice() {
    const req = {
      user_practice_id: joinId,
    };

    axios
      .post(`/remove-user-from-practice/`, req)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onAdminChange() {
    const req = {
      user_practice_id: joinId,
    };

    setAdmin(!isAdmin);

    axios
      .post(`/toggle-institutional-admin/`, req)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        fetchData();
      });
  }

  function onRoleChange(newVal) {
    setOccupationalVariety(newVal);

    if (!joinId) return;

    axios
      .post(`/users/update-occupational-variety/`, {
        user_practice_id: joinId,
        occupational_variety: newVal,
      })
      .then(() => {
        true;
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Card.Wrapper>
      <Card.Meta
        title={name}
        hasActions={userId && !roleInfo.admin_research_fellow ? true : false}
      />
      {joinId && (
        <div style={{ padding: "10px", backgroundColor: "floralwhite" }}>
          <Form.Radio
            label="Principal Investigator"
            toggle
            checked={isAdmin}
            onChange={() => onAdminChange()}
          />
        </div>
      )}
      <div style={{ padding: "10px", backgroundColor: "floralwhite" }}>
        <Form>
          <Form.Select
            label="Role"
            style={{
              marginBottom: "1em",
            }}
            required
            options={userVariety}
            placeholder="Select one..."
            value={occupationalVarietyValue}
            onChange={(e, d) => {
              onRoleChange(d.value);
            }}
          />
        </Form>
      </div>
      {userId && !roleInfo.admin_research_fellow && (
        <Card.Actions>
          <Button.Basic
            white={joinId ? false : true}
            primary={joinId ? true : false}
            text={joinId ? "Remove" : "Add"}
            onClick={onClick}
            loading={loading}
            disabled={loading || (!joinId && !occupationalVarietyValue)}
          />
        </Card.Actions>
      )}
    </Card.Wrapper>
  );
};

PracticeItem.defaultProps = {
  joinId: null,
  id: null,
  name: "",
  userId: null,
};
