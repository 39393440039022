import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

import { InlineLoader } from "@components/shared";

export default function QuerySummary({ queryRequestData }) {
  const [count, setCount] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(queryRequestData)]);

  function fetchData() {
    setLoading(true);

    const cancel = cancelRef.current;

    if (cancel) {
      cancel();
    }

    const req = {
      ...queryRequestData,
      summary: true,
    };

    axios
      .post(`/procedure-report/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        const res = data.response[0];

        if (res.message) {
          setSummaryData(null);
        }

        if (res && res.query) {
          // console.log(res.query);
          setCount(res.data.length);
          setSummaryData(res.query);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setCount(0);
        setSummaryData(null);
      });
  }

  return (
    <StyledSummary open={isOpen}>
      <button onClick={() => setOpen(!isOpen)}>
        There are currently
        <b>{count}</b>
        procedures in your query. Click to {isOpen
          ? "close the"
          : "view a"}{" "}
        summary.
      </button>
      {isOpen && (
        <div>
          <SummaryTable data={summaryData} />
          <button
            style={{ color: "var(--primaryColorDark)", fontWeight: "bold" }}
            onClick={() => setOpen(!isOpen)}
          >
            Close Summary
          </button>
        </div>
      )}
    </StyledSummary>
  );
}

const StyledSummary = styled.div`
  margin: 2em 0;
  height: ${(props) => (props.open ? "auto" : "40px")};

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
    display: flex;
    align-items: center;
    color: inherit;

    b {
      margin: 0 4px;
      font-size: 1.45em;
      color: var(--primaryColorDark);
    }

    span {
      margin-right: 8px;
    }
  }
`;

export const SummaryTable = ({ data }) => {
  if (
    !data ||
    data.message ||
    JSON.stringify(data) === "{}" ||
    JSON.stringify(data) === '{"joins":[]}'
  )
    return (
      <StyledTable>
        <p>No summary available.</p>
      </StyledTable>
    );

  const nonJoinKeys = Object.keys(data).filter((f) => f !== "joins");
  const joinKey = Object.keys(data).filter((f) => f === "joins");

  // console.log(data[joinKey]);

  return (
    <StyledTable>
      {nonJoinKeys.map((m) => (
        <li>
          {m}: {""}
          {typeof data[m] === "object" && (
            <React.Fragment>
              {new Intl.ListFormat("en", {
                style: "narrow",
              }).format(data[m].map((f) => `${f}`))}
            </React.Fragment>
          )}
          {typeof data[m] === "string" && (
            <React.Fragment>{data[m]}</React.Fragment>
          )}
        </li>
      ))}
      {data[joinKey] && data[joinKey].length > 0 && (
        <React.Fragment>
          {data[joinKey].map((m, i) => (
            <li key={i}>
              <b>
                <span style={{ textTransform: "capitalize" }}>
                  {m.query_mode}{" "}
                </span>
                {m.name}
              </b>
              {m.query_mode !== "never" && ":"}{" "}
              {m.data && m.data.length > 0 && (
                <React.Fragment>
                  {new Intl.ListFormat("en", {
                    style: "narrow",
                  }).format(m.data.map((f) => `${f}`))}
                </React.Fragment>
              )}
              {m.data && m.data.length === 0 && m.query_mode !== "never" && (
                <React.Fragment>(nothing added yet)</React.Fragment>
              )}
            </li>
          ))}
        </React.Fragment>
      )}
    </StyledTable>
  );
};

const StyledTable = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  background: #eee;
  padding: 1em 2em;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
`;
