import React, { useState, useEffect } from "react";
import axios from "axios";

import { Button, ViewError, InlineLink } from "@components/shared";
import { Wrapped } from "@components/Layouts";
import { Ranges, QueryContainerForm } from "@components/QueryContainer";
import QuerySummary from "@components/QueryBuilder/QuerySummary";
import { ReportContainer } from "@components/Reports";
import { visitValues } from "@components/QueryContainer/queryContainerOptions";
import { StyledSubmit } from "@components/QueryBuilder";

export default function QueryContainerDetail({ match, isAdmin }) {
  const { id } = match.params;
  const [container, setContainer] = useState(populateContainer());
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [visitMode, setVisitMode] = useState(visitValues.non_consistent);
  const [visits, setVisits] = useState([]);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(match)]);

  function fetchData() {
    axios
      .get(`/query-containers/${id}/`)
      .then(({ data }) => {
        setContainer(populateContainer(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  function onRunClick() {
    setShowReport(true);
  }

  function onResetClick() {
    setShowReport(false);
    window.scrollTo(0, 0);
  }

  if (error) {
    return <ViewError text={error} />;
  }

  return (
    <Wrapped
      title={container.name}
      button={
        container.editable && !editing && !showReport ? (
          <Button.Toggle
            edit
            onClick={() => setEditing(!editing)}
            open={editing}
          />
        ) : null
      }
    >
      {editing && (
        <QueryContainerForm
          id={id}
          name={container.name}
          keep={container.keep}
          global={container.global}
          sharedPractice={container.shared_practice}
          description={container.description}
          onSuccess={() => {
            setEditing(false);
            fetchData();
          }}
        />
      )}
      {!editing && (
        <React.Fragment>
          {container.description && (
            <p style={{ marginTop: "15px", fontSize: "1.15em" }}>
              {container.description}
            </p>
          )}
          <InlineLink to={`/queries`} text="View other queries" />
          {container.query_data && (
            <div style={{ marginBottom: "2em" }}>
              <QuerySummary queryRequestData={container.query_data} />
            </div>
          )}
          {showReport && (
            <React.Fragment>
              <ReportContainer
                queryContainerId={id}
                visits={visits}
                visitMode={visitMode}
              />
              <Button.Text text="Back" onClick={onResetClick} />
            </React.Fragment>
          )}
          {!showReport && (
            <React.Fragment>
              <div style={{ marginBottom: "2em" }}>
                <Ranges
                  visitMode={visitMode}
                  isCohort={visitMode === visitValues.consistent}
                  values={visits}
                  onChange={(e) => setVisits(e)}
                  onModeChange={(e) => setVisitMode(e)}
                />
              </div>
              <StyledSubmit>
                <Button.Basic
                  text="Run Report"
                  onClick={() => onRunClick()}
                  primary
                  disabled={
                    visits.length === 0
                    // (visitMode === visitValues.consistent && visits.length === 1)
                  }
                />
              </StyledSubmit>
              {container.editable && (
                <div
                  style={{
                    marginTop: "2em",
                    display: "flex",
                    justifyContent: "flex-end",
                    opacity: 0.75,
                    color: "var(--negativeColor)",
                    fontSize: "0.85em",
                  }}
                >
                  <DeleteButton id={id} />
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Wrapped>
  );
}

function populateContainer(d = {}) {
  return {
    description: d.description || "",
    editable: d.editable || false,
    global: d.global || false,
    keep: d.keep || false,
    name: d.name || "",
    query_data: d.query_data || null,
    shared_practice: d.shared_practice || false,
  };
}

const DeleteButton = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(`/query-containers/${id}/delete/`, { query_container_id: id })
      .then(() => (window.location = `/queries`))
      .catch((err) => alert(err));
  }

  return (
    <div>
      {confirm ? (
        <div>
          <p style={{ marginBottom: "0", color: "initial", fontWeight: 600 }}>
            Are you sure you want to delete?
          </p>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button.Text
              text="Cancel"
              primary
              onClick={() => setConfirm(false)}
              loading={loading}
              disabled={loading}
            />
            <Button.Text
              text="Delete Query"
              primary
              onClick={onClick}
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
      ) : (
        <Button.Text
          text="Delete Query"
          primary
          onClick={() => setConfirm(true)}
          loading={loading}
          disabled={loading}
        />
      )}
    </div>
  );
};
