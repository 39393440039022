import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import { useSelector } from "react-redux";

import { BasicForm, DateField, Button } from "@components/shared";
import { genderOptions, raceOptions, ethnicityOptions } from "./patientOptions";
import { ReadOnlyField } from "@components/shared/Forms/CustomInputComponent";

import { getRoleInfo } from "@util/roleInfo";

export default function PatientForm({
  birthYear,
  dataConsentedAt,
  ethnicity,
  firstName,
  gender,
  id,
  lastName,
  newlyDiagnosedDate,
  practiceId,
  race,
  status,
  staging,
  onSuccess,
  onFormSubmit,
  isRegistryRequest,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({
    birth_year: "",
    data_consented_at: null,
    ethnicity: null,
    first_name: "",
    gender: null,
    last_name: "",
    newly_diagnosed_date: null,
    race: null,
  });

  useEffect(() => {
    setFormValues({
      birth_year: birthYear,
      data_consented_at: dataConsentedAt,
      ethnicity,
      first_name: firstName,
      gender,
      last_name: lastName,
      newly_diagnosed_date: newlyDiagnosedDate,
      race,
    });
  }, [firstName]);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    if (practiceId) {
      req.practice_id = practiceId;
    }

    if (staging) return onFormSubmit(req);

    setLoading(true);
    setError(null);

    axios
      .post(`/patients/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Patient Created!");
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      patient_id: id,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/patients/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Patient Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  const disabled =
    !formValues.first_name ||
    !formValues.last_name ||
    !formValues.gender ||
    !formValues.race ||
    !formValues.newly_diagnosed_date ||
    !formValues.data_consented_at ||
    !formValues.birth_year ||
    !/^(19|20)\d{2}$/.test(formValues.birth_year);

  const readOnly = id && !roleInfo.admin_research_fellow;

  const fields = [
    // {
    //   name: "patient_ident",
    //   initialValue: "",
    //   label: "Patient ID",
    //   required: true,
    //   schema: () => Yup.string().required("Required"),
    // },
    {
      name: "first_name",
      initialValue: firstName,
      label: "First Name",
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "last_name",
      initialValue: lastName,
      label: "Last Name",
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "gender",
      initialValue: gender,
      label: "Gender",
      select: true,
      required: true,
      options: genderOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "ethnicity",
      initialValue: ethnicity,
      label: "Ethnicity",
      select: true,
      required: true,
      options: raceOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      grouped: true,
      fields: [
        {
          name: "birth_year",
          initialValue: birthYear,
          label: "Birth Year",
          required: true,
          schema: () =>
            Yup.string()
              .matches(/(?:19\d{2}|20[01][0-9]|2023)/, "Not a valid birth year")
              .required("Required"),
        },
        {
          name: "newly_diagnosed_date",
          initialValue: newlyDiagnosedDate
            ? new Date(newlyDiagnosedDate)
            : null,
          datepicker: true,
          maxDate: new Date(),
          label: "Date of Glaucoma diagnosis",
          required: false,
          schema: () => Yup.string().nullable(),
        },
        {
          name: "data_consented_at",
          initialValue: dataConsentedAt ? new Date(dataConsentedAt) : null,
          datepicker: true,
          maxDate: new Date(),
          label: "Date Consented At",
          required: false,
          schema: () => Yup.string().nullable(),
        },
      ].map((m) => ({
        ...m,
        readOnly: isRegistryRequest
          ? true
          : id && !roleInfo.admin_research_fellow
          ? true
          : false,
      })),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: isRegistryRequest
        ? true
        : id && !roleInfo.admin_research_fellow
        ? true
        : false,
    }))
    .filter((f) => (!staging ? f.name !== "patient_ident" : true))
    .filter((f) =>
      roleInfo.user ? true : !["first_name", "last_name"].includes(f.name)
    );

  return (
    <div>
      <Form>
        {roleInfo.user && (
          <React.Fragment>
            <Form.Input
              label="First Name"
              required
              readOnly={readOnly}
              value={formValues.first_name}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  first_name: e.target.value,
                })
              }
            />
            <Form.Input
              label="Last Name"
              readOnly={readOnly}
              required
              value={formValues.last_name}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  last_name: e.target.value,
                })
              }
            />
          </React.Fragment>
        )}
        {readOnly ? (
          <ReadOnlyField
            label="Gender"
            value={
              formValues.gender &&
              genderOptions.find((f) => f.value === formValues.gender)
                ? genderOptions.find((f) => f.value === formValues.gender).text
                : ""
            }
          />
        ) : (
          <Form.Select
            label="Gender"
            value={formValues.gender}
            onChange={(e, d) =>
              setFormValues({
                ...formValues,
                gender: d.value,
              })
            }
            required
            options={genderOptions}
            placeholder="Select one..."
          />
        )}
        {readOnly ? (
          <ReadOnlyField
            label="Race"
            value={
              formValues.race &&
              raceOptions.find((f) => f.value === formValues.race)
                ? raceOptions.find((f) => f.value === formValues.race).text
                : ""
            }
          />
        ) : (
          <Form.Select
            label="Race"
            value={formValues.race}
            onChange={(e, d) =>
              setFormValues({
                ...formValues,
                race: d.value,
                ethnicity: null,
              })
            }
            required
            options={raceOptions}
            placeholder="Select one..."
          />
        )}
        {readOnly ? (
          <ReadOnlyField
            label="Ethnicity"
            value={
              formValues.ethnicity &&
              formValues.race &&
              ethnicityOptions[formValues.race].find(
                (f) => f.value === formValues.ethnicity
              )
                ? ethnicityOptions[formValues.race].find(
                    (f) => f.value === formValues.ethnicity
                  ).text
                : "-"
            }
          />
        ) : (
          <Form.Select
            label="Ethnicity"
            value={formValues.ethnicity}
            onChange={(e, d) =>
              setFormValues({
                ...formValues,
                ethnicity: d.value,
              })
            }
            options={formValues.race ? ethnicityOptions[formValues.race] : []}
            placeholder="Select one..."
            disabled={!formValues.race || formValues.race === 99}
          />
        )}
        <Form.Group widths="equal">
          <Form.Input
            label="Birth Year"
            readOnly={readOnly}
            value={formValues.birth_year}
            required
            onChange={(e) =>
              setFormValues({
                ...formValues,
                birth_year: e.target.value,
              })
            }
          />
          {readOnly ? (
            <ReadOnlyField
              label="Date of Glaucoma diagnosis"
              value={
                formValues.newly_diagnosed_date
                  ? new Date(
                      formValues.newly_diagnosed_date
                    ).toLocaleDateString()
                  : ""
              }
            />
          ) : (
            <Form.Field required>
              <label>Date of Glaucoma diagnosis</label>
              <DateField
                selected={
                  formValues.newly_diagnosed_date
                    ? new Date(formValues.newly_diagnosed_date)
                    : null
                }
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    newly_diagnosed_date: e,
                  })
                }
                maxDate={new Date()}
              />
            </Form.Field>
          )}
          {readOnly ? (
            <ReadOnlyField
              label="Date Consented At"
              value={
                formValues.data_consented_at
                  ? new Date(formValues.data_consented_at).toLocaleDateString()
                  : ""
              }
            />
          ) : (
            <Form.Field required>
              <label>Date Consented At</label>
              <DateField
                selected={
                  formValues.data_consented_at
                    ? new Date(formValues.data_consented_at)
                    : null
                }
                showYearDropdown
                dropdownMode="select"
                autoComplete="off"
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    data_consented_at: e,
                  })
                }
                maxDate={new Date()}
              />
            </Form.Field>
          )}
        </Form.Group>
        {!readOnly && (
          <Button.Basic
            primary
            type="Submit"
            loading={isLoading}
            onClick={() => onSubmit(formValues)}
            disabled={disabled}
            text="Save"
          />
        )}
      </Form>
    </div>
  );

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={isLoading}
        onSubmit={onSubmit}
        showButton={
          isRegistryRequest
            ? false
            : !id
            ? true
            : roleInfo.admin_research_fellow
            ? true
            : false
        }
      />
    </div>
  );
}

PatientForm.defaultProps = {
  birthdate: "",
  dataConsentedAt: null,
  ethnicity: null,
  firstName: "",
  gender: null,
  id: null,
  isRegistryRequest: false,
  lastName: "",
  newlyDiagnosedDate: null,
  practiceId: null,
  race: null,
  registryExitDate: "",
  staging: false,
  onSuccess: () => alert("On Success"),
};
