import React from "react";

import { Wrapped } from "@components/Layouts";

export default function Contact() {
  return (
    <Wrapped title="Contact">
      <p>Questions or concerns?</p>
      <p>
        Please contact{" "}
        <a href="mailto:imperial.ukmptltstudy@nhs.net">
          imperial.ukmptltstudy@nhs.net
        </a>
      </p>
    </Wrapped>
  );
}
