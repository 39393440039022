import React, { useState, useEffect } from "react";
import { Divider, Form } from "semantic-ui-react";
import axios from "axios";
import toast from "react-hot-toast";

import { getUKTime } from "@util/getUKTime";

import {
  bcvaMethodOptions,
  bcvaOptions,
  iopVarietyOptions,
  lensOptions,
  consciousOptions,
  powerOptions,
  statusOptions,
  sweepCountOptions,
  sweepVelocityOptions,
  techniqueOptions,
  wavePowerOptions,
  waveSpotOptions,
  waveDurationOptions,
} from "./procedureOptions";

import { Button, DateField, NumberField } from "@components/shared";
import { ProcedureFormIops } from "./";

const CONTINUOUS_WAVE_VALUE = 2;

export default function ProcedureForm({
  id,
  patientId,
  practiceId,
  procedureData,
  selectedEye,
  onSuccess,
  onUpdateSuccess,
  roleInfo,
  showIOP,
}) {
  const [procedure, setProcedure] = useState(populateProcedure());

  useEffect(() => {
    if (procedureData) {
      setProcedure(populateProcedure(procedureData));
    }
  }, [JSON.stringify(procedureData)]);

  useEffect(() => {
    if (selectedEye && !procedureData) {
      setProcedure({ ...procedure, eye: selectedEye });
    }
  }, [selectedEye]);

  function onSubmitClick() {
    if (id) {
      return onUpdate();
    }

    const req = {
      ...procedure,
      patient_id: patientId,
      status: 1,
    };

    if (practiceId) {
      req.practice_id = practiceId;
    }

    axios
      .post(`/procedures/`, req)
      .then(({ data }) => {
        if (procedure.anesthesia_id) {
          return addAnesthesiaJoin(data.response[0].id);
        }

        if (onSuccess) {
          return onSuccess(data.response[0].id);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onUpdate() {
    const req = {
      ...procedure,
      procedure_id: id,
    };

    delete req.iop_entries;

    axios
      .put(`/procedures/${id}/`, req)
      .then(() => {
        if (
          procedureData &&
          procedureData.anesthesia &&
          procedureData.anesthesia.option_id !== procedure.anesthesia_id
        ) {
          removeAnesthesiaJoin();
        } else {
          onUpdateSuccess();
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function removeAnesthesiaJoin() {
    const req = {
      entity: `AnesthesiaProcedure`,
      id: procedureData.anesthesia.join_id,
    };

    axios
      .post(`/option-status/`, req)
      .then(() => addAnesthesiaJoin(id))
      .catch((err) => {
        toast.error(err);
      });
  }

  function addAnesthesiaJoin(proId) {
    const req = {
      id: procedure.anesthesia_id,
      procedure_id: proId,
    };

    axios
      .post(`/procedures/${proId}/add-anesthesias/`, req)
      .then(() => {
        onSuccess(proId);
      })
      .catch((err) => {});
  }

  const floatFields = [
    // { name: "duty_cycle", label: "Duty Cycle", min: 0, max: DEFAULT_MAX_VALUE },
    {
      name: "axial_length",
      label: "Axial Length (10-30mm)",
      min: 0,
      max: 30,
    },
    // { name: "refraction", label: "Refraction", min: 0, max: DEFAULT_MAX_VALUE },
  ].map((m, i) => ({ ...m, key: i + 1 }));

  return (
    <div>
      <Form>
        {/* <h4>Pre-Op MicroPulse TLT Information</h4> */}
        <Form.Field required>
          <label>Procedure Date</label>
          <DateField
            selected={
              procedure.operation_date ? procedure.operation_date : new Date()
            }
            onChange={(e) => setProcedure({ ...procedure, operation_date: e })}
            maxDate={new Date()}
          />
        </Form.Field>
        {!selectedEye && !id && (
          <Form.Field>
            <label>Eye</label>
            <Form.Group>
              <Form.Radio
                // label="Left"
                label="OS"
                onChange={() => setProcedure({ ...procedure, eye: 1 })}
                checked={procedure.eye === 1}
              />
              <Form.Radio
                // label="Right"
                label="OD"
                onChange={() => setProcedure({ ...procedure, eye: 2 })}
                checked={procedure.eye === 2}
              />
            </Form.Group>
          </Form.Field>
        )}
        {id && roleInfo && roleInfo.admin_research_fellow && (
          <Form.Select
            label="Status"
            value={procedure.status}
            options={statusOptions}
            search
            placeholder="Select one..."
            onChange={(e, d) => setProcedure({ ...procedure, status: d.value })}
          />
        )}
        <Divider />
        {showIOP && (
          <React.Fragment>
            <ProcedureFormIops
              procedureId={id}
              creating={id ? false : true}
              fetchData={onSuccess}
              items={procedure.iop_entries}
              onChange={(e) =>
                setProcedure({
                  ...procedure,
                  iop_entries: e,
                })
              }
            />
            <Divider />
          </React.Fragment>
        )}
        <Form.Select
          label="IOP Variety"
          value={procedure.iop_variety}
          required
          options={iopVarietyOptions}
          search
          placeholder="Select one..."
          onChange={(e, d) =>
            setProcedure({ ...procedure, iop_variety: d.value })
          }
        />
        <Form.Group widths="equal">
          <Form.Select
            label="BCVA Method"
            required
            value={procedure.bcva_method}
            options={bcvaMethodOptions}
            search
            placeholder="Select one..."
            onChange={(e, d) =>
              setProcedure({ ...procedure, bcva_method: d.value })
            }
          />
          <Form.Select
            label="BCVA"
            required
            value={procedure.bcva}
            options={bcvaOptions(procedure.bcva_method)}
            search
            placeholder="Select one..."
            onChange={(e, d) => setProcedure({ ...procedure, bcva: d.value })}
          />
        </Form.Group>
        {floatFields.map((m) => (
          <Form.Field key={m.key}>
            <label>{m.label}</label>
            <NumberField
              value={procedure[m.name]}
              decimalScale={2}
              onValueChange={(e) =>
                setProcedure({ ...procedure, [m.name]: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= m.min && floatValue <= m.max) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
        ))}
        <Form.Select
          label="Conscious Level"
          required
          value={procedure.consciousness_level}
          options={consciousOptions}
          search
          placeholder="Select one..."
          onChange={(e, d) =>
            setProcedure({ ...procedure, consciousness_level: d.value })
          }
        />
        <AnesthesiaField
          value={procedure.anesthesia_id}
          onChange={(d) => {
            setProcedure({
              ...procedure,
              anesthesia_id: d,
            });
          }}
          procedureId={id}
          joinData={procedure.anesthesia}
        />
        <h4>Refraction</h4>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Sphere (-20 to 20)</label>
            <NumberField
              value={procedure.sphere}
              decimalScale={2}
              onValueChange={(e) =>
                setProcedure({ ...procedure, sphere: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= -20 && floatValue <= 20) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Cylinder (-15 to 15)</label>
            <NumberField
              value={procedure.cylinder}
              decimalScale={2}
              onValueChange={(e) =>
                setProcedure({ ...procedure, cylinder: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= -15 && floatValue <= 15) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Axis (0 to 180 deg)</label>
            <NumberField
              value={procedure.axis}
              decimalScale={0}
              onValueChange={(e) =>
                setProcedure({ ...procedure, axis: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= 0 && floatValue <= 180) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        <h4>Visual Field</h4>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>Mean Deviation (-35 to 5)</label>
            <NumberField
              value={procedure.mean_deviation}
              decimalScale={2}
              onValueChange={(e) =>
                setProcedure({ ...procedure, mean_deviation: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= -35 && floatValue <= 5) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>VFI (0-100%)</label>
            <NumberField
              value={procedure.vfi}
              decimalScale={0}
              onValueChange={(e) =>
                setProcedure({ ...procedure, vfi: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= 0 && floatValue <= 100) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>PSD (-15 to 0 Db)</label>
            <NumberField
              value={procedure.psd}
              decimalScale={0}
              onValueChange={(e) =>
                setProcedure({ ...procedure, psd: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= -15 && floatValue <= 0) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        <h4>OCT</h4>
        <Form.Field>
          <label>RFNL Thickness (0-120)</label>
          <NumberField
            value={procedure.rfnl_thickness}
            decimalScale={0}
            onValueChange={(e) =>
              setProcedure({ ...procedure, rfnl_thickness: e.formattedValue })
            }
            isAllowed={(values) => {
              const { floatValue } = values;
              return (
                (floatValue >= 0 && floatValue <= 120) ||
                floatValue === undefined
              );
            }}
          />
        </Form.Field>
        <h4>Laser Parameters Information</h4>
        <Form.Select
          label="Continuous Wave / MicroPulse"
          required
          placeholder="Select one..."
          value={procedure.laser_parameter}
          onChange={(e, d) =>
            setProcedure({
              ...procedure,
              duration: null,
              laser_parameter: d.value,
              number_of_spots: null,
              power: null,
              sweep_velocity: d.value === CONTINUOUS_WAVE_VALUE ? 8 : null,
              technique: d.value === CONTINUOUS_WAVE_VALUE ? 3 : null,
              sweep_count: d.value === CONTINUOUS_WAVE_VALUE ? 11 : null,
            })
          }
          options={[
            { text: "MicroPulse", value: 1 },
            { text: "Continuous Wave", value: CONTINUOUS_WAVE_VALUE },
          ].map((m, i) => ({ ...m, key: i }))}
        />
        <Form.Field>
          <label>Viscous Agent</label>
          <Form.Radio
            toggle
            checked={procedure.viscous_agent}
            label={procedure.viscous_agent ? "Yes" : "No"}
            onChange={() =>
              setProcedure({
                ...procedure,
                viscous_agent: !procedure.viscous_agent,
              })
            }
          />
        </Form.Field>
        <Form.Field>
          <label>Duty Cycle (read-only)</label>
          <p>{procedure.duty_cycle}</p>
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Select
            label="Power"
            required
            value={procedure.power}
            options={
              procedure.laser_parameter === 2 ? wavePowerOptions : powerOptions
            }
            search
            placeholder="Select one..."
            onChange={(e, d) => setProcedure({ ...procedure, power: d.value })}
          />
          <Form.Select
            label="Angular Treatment"
            required
            value={procedure.technique}
            options={techniqueOptions}
            disabled={procedure.laser_parameter === CONTINUOUS_WAVE_VALUE}
            search
            placeholder="Select one..."
            onChange={(e, d) =>
              setProcedure({ ...procedure, technique: d.value })
            }
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            label="Sweep Speed Velocity"
            required
            value={procedure.sweep_velocity}
            options={sweepVelocityOptions}
            search
            placeholder="Select one..."
            disabled={procedure.laser_parameter === CONTINUOUS_WAVE_VALUE}
            onChange={(e, d) =>
              setProcedure({ ...procedure, sweep_velocity: d.value })
            }
          />
          <Form.Select
            label="Number of Sweeps"
            required
            value={procedure.sweep_count}
            options={sweepCountOptions}
            search
            placeholder="Select one..."
            disabled={procedure.laser_parameter === CONTINUOUS_WAVE_VALUE}
            onChange={(e, d) =>
              setProcedure({ ...procedure, sweep_count: d.value })
            }
          />
        </Form.Group>
        {procedure.laser_parameter === CONTINUOUS_WAVE_VALUE && (
          <Form.Group widths="equal">
            <Form.Select
              label="Duration"
              required
              value={procedure.duration}
              options={waveDurationOptions}
              search
              placeholder="Select one..."
              onChange={(e, d) =>
                setProcedure({ ...procedure, duration: d.value })
              }
            />
            <Form.Select
              label="Number of Spots"
              required
              value={procedure.number_of_spots}
              options={waveSpotOptions}
              search
              placeholder="Select one..."
              onChange={(e, d) =>
                setProcedure({ ...procedure, number_of_spots: d.value })
              }
            />
          </Form.Group>
        )}
        {procedure.technique &&
          [1, 2].includes(procedure.technique) &&
          procedure.sweep_count && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px 0",
              }}
            >
              <p style={{ margin: 0, fontSize: "22px" }}>
                Total Sweep Count:{" "}
                {procedure.sweep_count *
                  techniqueOptions.filter(
                    (f) => f.value == procedure.technique
                  )[0].sections}
              </p>
            </div>
          )}
        <Button.Basic
          type="submit"
          primary
          disabled={
            !procedure.operation_date || !procedure.eye
            // (!id && !procedure.iop_entries[0].value) ||
            // (!id && !procedure.iop_entries[0].variety) ||
            // (!id && !procedure.iop_entries[0].time) ||
            // // !procedure.lens_status ||
            // !procedure.bcva_method ||
            // !procedure.bcva ||
            // !procedure.duty_cycle ||
            // (procedure.laser_parameter === 2 && !procedure.number_of_spots) ||
            // (procedure.laser_parameter === 2 && !procedure.duration) ||
            // !procedure.iop_variety ||
            // procedure.axial_length === "-" ||
            // (procedure.axial_length && parseInt(procedure.axial_length) < 10) ||
            // (procedure.axial_length && parseInt(procedure.axial_length) > 30) ||
            // !procedure.power ||
            // !procedure.technique ||
            // !procedure.sweep_velocity ||
            // !procedure.sweep_count
          }
          text={!id ? "Create Procedure" : "Update Procedure"}
          onClick={onSubmitClick}
        />
      </Form>
    </div>
  );
}

function populateProcedure(data = {}) {
  return {
    anesthesia_id: data.anesthesia ? data.anesthesia.option_id : null,
    anesthesia: data.anesthesia || null,
    axial_length: data.axial_length || "",
    axis: data.axis || "",
    bcva_method: data.bcva_method || null,
    bcva: data.bcva || "",
    consciousness_level: data.consciousness_level || null,
    cylinder: data.cylinder || "",
    duration: data.duration || null,
    duty_cycle: data.duty_cycle || "31.30",
    eye: data.eye || null,
    iop_entries: data.iop_entries || [
      { variety: 1, time: getUKTime(), value: "" },
    ],
    iop_variety: data.iop_variety || null,
    iop: data.iop || "",
    laser_parameter: data.laser_parameter || null,
    lens_status: data.lens_status || null,
    mean_deviation: data.mean_deviation || "",
    number_of_spots: data.number_of_spots || null,
    oct: data.oct || "",
    operation_date: data.operation_date
      ? new Date(data.operation_date)
      : new Date(),
    psd: data.psd || "",
    power: data.power || null,
    refraction: data.refraction || null,
    rfnl_thickness: data.rfnl_thickness || "",
    sphere: data.sphere || "",
    status: data.status || null,
    sweep_count: data.sweep_count || null,
    sweep_velocity: data.sweep_velocity || null,
    technique: data.technique || null,
    vfi: data.vfi || "",
    viscous_agent: data.viscous_agent || false,
  };
}

const AnesthesiaField = ({ value, onChange, procedureId, joinData }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);
    axios
      .post(`/retrieve-anesthesias/`, { status: 1 })
      .then(({ data }) => {
        setOptions(
          data.response
            .map((m) => ({
              key: m.id,
              value: m.id,
              text: m.name,
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onValueChange(val) {
    onChange(val);
  }

  return (
    <Form.Select
      label="Anaesthesia"
      value={value}
      onChange={(e, d) => onValueChange(d.value)}
      placeholder="Select one..."
      options={options}
      loading={loading}
      search
      required
    />
  );
};
