import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useSelector } from "react-redux";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";
import { size, device } from "@util/breakpoints";

export default function Table({ isAdmin, practiceId }) {
  const [activeFilter, setActiveFilter] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const roleInfo = getRoleInfo(useSelector((state) => state));
  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/patients/${d.id}`} />,
    },
    {
      Header: "Patient ID",
      accessor: "formatted_ident",
      show: roleInfo.user,
      width: 150,
    },
    {
      Header: "First Name",
      accessor: "first_name",
      show: roleInfo.user,
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      show: roleInfo.user,
    },
    {
      Header: "Info",
      accessor: "info_string",
      show: roleInfo.admin,
    },
  ].map((m) => ({ ...m, sortable: false }));

  const additionalPostData = {
    status: JSON.parse(activeFilter),
    value: searchValue.length >= 2 ? searchValue : null,
  };

  if (practiceId) {
    additionalPostData.practice_id = practiceId;
  }

  return (
    <React.Fragment>
      <FilterSelect
        options={dropdownOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Patients"
      />
      <Form style={{ marginBottom: "1em" }}>
        <Form.Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search patient identifier..."
          width={window.innerWidth >= size.laptop ? 4 : 16}
        />
      </Form>
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Patients"
        filter={activeFilter}
        filterOptions={filterOptions}
        keyName="data"
        url="/retrieve-patients/"
      />
    </React.Fragment>
  );
}

const dropdownOptions = [
  { text: "All", value: `[1, 2]` },
  { text: "Active", value: 1 },
  { text: "Inactive", value: 2 },
];

const filterOptions = [
  { name: "name", obj: "self" },
  { name: "location_name", obj: "self" },
];
